import SalonLogo from '@components/frontpage/SalonLogo'
import Link from 'next/link'
import H2 from '@components/frontpage/H2'
import ListOut from '@components/shared/ListOut'
import ButtonWrapper from '@components/shared/ButtonWrapper'
import NavLinkColor from '@components/shared/NavLinkColor'
import Icon from '@components/shared/Icon'
import Empty from '@components/shared/Empty'

import useT from '@hooks/useTranslation'
import salonLink from '@utils/links/salonLink'

import { SALONS_PAGE } from '@constants/routes'

import styles from 'styles/SalonList.module.css'

const salonsLink = { t: 'Zobacz więcej', to: SALONS_PAGE, color: 'teal', iconRight: <Icon>arrow_right_alt</Icon> }

const SalonList = ({ salons, text, linkFunction = salonLink }) => {
    if (!salons || salons.length < 1) return null

    return (
        <section className="container">
            <H2>{ useT(text || 'Firmy motoryzacyjne z katalogiem u nas') }</H2>

            <Salons salons={salons} linkFunction={linkFunction} />
            <ButtonWrapper style={{ width: 276, margin: '40px auto' }}>
                <NavLinkColor {...salonsLink} />
            </ButtonWrapper>
        </section>
    )
}

function Salons ({ salons, empty = '', linkFunction = salonLink }) {
    return salons?.length > 0
        ? <Grid>
            <ListOut id="_id" items={salons} Component={SalonPresentation}
                linkFunction={linkFunction} />
          </Grid>

        : <Empty text={useT(empty) + '.'} />
}

function Grid ({ children }) {
    return (
        <div className={styles.wrapper}>
            { children }
        </div>
    )
}

function SalonPresentation ({ _id, dealer: { firm_name, subdomain },
    linkFunction }) {

    return (
        <Link href={linkFunction(subdomain)}>
            <a>
                <SalonLogo _id={_id} alt={firm_name} />
            </a>
        </Link>
    )
}

export default SalonList
export { Salons }
