import styles from 'styles/frontpage/H1.module.css'

const H1 = ({ children }) => (
    <div className="container">
        <h1 className={styles.h1}>
            {children}
        </h1>
    </div>
)

export default H1