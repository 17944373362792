export default [
    [
        'Ogłoszenia w kategoriach',
        [
            'Samochody dostawcze',
            'Samochody ciężarowe',
            'Części do samochodów dostawczych',
            'Serwis samochodów dostawczych',
            'Narzędzia',
            'Maszyny rolnicze',
            'Maszyny budowlane',
            'Przyczepy',
            'Kupię samochód dostawczy',
        ],
        [
            '/samochody-dostawcze',
            '/samochody-ciezarowe',
            '/czesci-auta-dostawcze',
            '/serwis-aut-dostawczych',
            '/narzedzia-warsztatowe-wyposazenie',
            '/maszyny-rolnicze-sprzedaz',
            '/maszyny-budowlane-sprzedaz',
            '/przyczepy',
            '/kupie/samochody-dostawcze',
        ],
    ],
    [
        'Darmowe ogłoszenia – wypróbuj pierwszy rok za darmo! Otrzymaj swoją indywidualną subdomenę, na której zaprezentujesz swoją całą ofertę pojazdów lub usług',
        [
            'Zarejestruj się',
        ],
        [
            '/konto/zaloguj',
        ],
    ],
]