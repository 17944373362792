import ListOut from './ListOut'
import JsonLdFAQ from 'src/seo/jsonld/JsonLdFAQ'
import strip from '@utils/html/strip'
import styles from 'styles/FAQ.module.css'

function FAQComponent ({ page = 'generic-faq', qa, title = 'dostawczaki.pl', includeJsonLd = true }) {
    if (qa?.title) { // accept object format with title (default - array of qa)
        title = qa.title
        qa = qa.qa || qa.qaArray
    }

    if (!qa) return null

    return (
        <div key={page} className={`${styles.wrapper} container`}>
            { includeJsonLd && <JsonLdFAQ qa={qa} />}
            <h2>{ `${title} FAQ:` }</h2>
            <br/>
            { renderFAQItems(qa) }
        </div>
    )
}

function renderFAQItems (qa) {
    return <ListOut items={qa} Component={FAQItem} />
}

function FAQItem ({ question, answer, keywords }) {
    const answerHtml = activateLinks(
        boldenKeywords(answer, keywords))

    return (
        <details className={styles.qa_item}>
            <summary><b>{ strip(question) }</b></summary>
            <div className={styles.answer}
                dangerouslySetInnerHTML={{ __html: answerHtml }}/>
        </details>
    )
}

function activateLinks (text) {
    const LINK_TEXT = 'dostawczaki.pl'
    const REGEXP = /(https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/
    const LINK = href => `<a href="${href}">${LINK_TEXT}</a>`
    const linkExists = text.lastIndexOf('https') !== -1
    let newText
    if (linkExists) {
        const match = text.match(REGEXP)
        if (match) {
            const [href] = match
            const index = match.index
            const length = href.length
            newText = text.slice(0, index) + LINK(href) + text.slice(index + length)
        }
    }

    return newText || text
}

function boldenKeywords (text, keywordArray = [], startWhiteSpace = '\\s+', endWhiteSpace = '\\s+', startTag = ' <b>', endTag = '</b> ') {
    for (const keyword of keywordArray) {
        const regexp = new RegExp(`${startWhiteSpace}${keyword}${endWhiteSpace}`, 'g')
        const chunks = text.split(regexp)
        text = chunks.join(`${startTag}${keyword}${endTag}`)
    }

    return text
}

export default FAQComponent
export { boldenKeywords }