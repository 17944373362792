import Head from 'next/head'

export default function jsonLd (object) {
    return object
        ? <Head>
            <script
                dangerouslySetInnerHTML={{ __html: JSON.stringify(object) }}
                type="application/ld+json"/>
          </Head>

        : null
}