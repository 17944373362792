import { ARTICLE_ID } from '@components/html/Article'

const ScrollToArticle = ({ article: { headText, buttonText }}) => {
    const onClick = () => {
        const article = document.getElementById(ARTICLE_ID)
        if (article) article.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className="container">
            <p style={{ textAlign: 'center', padding: '20px 0' }}>
                <b>{ headText }.</b>
                <button style={{ background: 'inherit', border: 'none', color: 'var(--teal)', cursor: 'pointer', display: 'inline' }} onClick={onClick}>
                    { buttonText }...</button>
            </p>
        </div>
    )
}

export default ScrollToArticle