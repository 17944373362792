import Business from '@constants/Business'
import coverPhoto from '@utils/links/coverPhoto'

export default function frontpageFeatured (offer) {
    return {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": Business.host,
        "image": offer
            ? coverPhoto(offer.tracking.pid)
            : Business.host + '/assets/icons/large-samochody-fb.jpeg',
        "potentialAction": {
          "@type": "SearchAction",
          "target": Business.host + "/samochody-osobowe?fraza={q}",
          "query-input": "required name=q"
        }
    }
}