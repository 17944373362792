import ImageWithFallback from '@components/image/WithFallback'
import salonLogo from '@utils/links/salonLogo'

import styles from 'styles/SalonListLogo.module.css'

export default function SalonLogo ({ _id, alt = 'logo', ...rest }) {
    return <div className={styles.logo}>
        <ImageWithFallback
            src={salonLogo(_id)}
            alt={alt}
            layout="fill"
            objectFit="contain"
            {...rest}
        />
    </div>
}