import Business from '@constants/Business'

const frontpage = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": Business.host,
    "logo": Business.host + '/assets/icons/large-samochody-fb.jpeg',
    "name": "dostawczaki.pl",
    "description": "Serwis ogłoszeń motoryzacyjnych"
}

export default frontpage