import ButtonWrapper from '@components/shared/ButtonWrapper'
import NavLinkColor from '@components/shared/NavLinkColor'
import NavLinkButton from '@components/shared/NavLinkButton'
import Icon from '@components/shared/Icon'

import useT from '@hooks/useTranslation'
import withUser from '@hoc/withUser'
import MessageHelper from '@utils/messageHelper'

import styles from 'styles/DealerInvitation.module.css'
import sections from 'styles/Sections.module.css'

const DealerInvitation = ({ isLoggedIn, h2, h3, linkT, linkTo }) => {
    const jestesZalogowany = useT('Wyloguj się aby założyć konto')
    const linkText = useT(linkT)
    return (
        <div className="container" style={{ height: '100%' }}>
            <div className={`${sections.col_center} ${styles.invitation}`}>
                <h2>{ useT(h2) }</h2>
                <h3>{ useT(h3) }!</h3>

                <ButtonWrapper>
                    { isLoggedIn
                        ? <NavLinkButton
                            onClick={() => MessageHelper.message(jestesZalogowany + '.')}
                            color="teal">
                            {linkText} <Icon>arrow_right_alt</Icon>
                          </NavLinkButton>

                        : <NavLinkColor {...{
                            t: linkT,
                            to: linkTo,
                            color: 'teal',
                            iconRight: <Icon>arrow_right_alt</Icon> }} /> }
                </ButtonWrapper>
            </div>
        </div>
    )
}

export default withUser(DealerInvitation)