import React from 'react'
import Link from 'next/link'
import useT from '@hooks/useTranslation'

import styles from 'styles/FooterLinks.module.css'

const FrontpageLinks = ({ sections }) => {
    if (!Array.isArray(sections)) return null
    if (!Array.isArray(sections[0])) return null
    return (
        <div className={styles.wrapper}>
            {
                sections.map(dataSet => {
                    if (!dataSet) return null
                    const [title, linkNames, linkRelativePaths, makePrefix] = dataSet
                    if (!linkNames.length || !linkRelativePaths.length) return null

                    if (linkNames.length !== linkRelativePaths.length) {
                        console.log('Length disparity in names/urls')
                        console.log(title)
                        console.log(linkNames)
                        console.log(linkRelativePaths)
                        return null
                    }

                    let translatedTitle
                    if (title.indexOf('Popularne modele') !== -1) {
                        translatedTitle = title.replace('Popularne modele', useT('Popularne modele'))
                    } else {
                        translatedTitle = useT(title)
                    }

                    return (
                        <div className={styles.row} key={title}>
                          <div className={styles.item}>
                            <h2>{ translatedTitle }</h2>
                            {
                                linkNames.map((name, i) => {
                                    const path = linkRelativePaths[i]
                                    const prefix = makePrefix ? `${makePrefix} ` : ''
                                    const text = title !== 'Szukaj w kategoriach'
                                      ? name
                                      : useT(name)
                                    const displayName = `${prefix}${text}`
                                    const linkTitle = displayName

                                    return (
                                      <h3 key={path + i} className="inline-h">
                                        <Link href={path} prefetch={false}>
                                            <a title={linkTitle}>
                                                { displayName }
                                            </a>
                                        </Link>
                                      </h3>
                                    )
                                })
                            }
                          </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default FrontpageLinks