import Layout from '@components/layout/Main'
import Background from '@components/image/Background'
import FrontpageForm from '@components/search/forms/FrontpageForm'
import H1 from '@components/frontpage/H1'
import FeaturedOffers from '@components/frontpage/FeaturedOffers'
import ScrollToArticle from '@components/frontpage/ScrollToArticle'
import AccountLink from '@components/frontpage/DealerAccountLink'
import NavLinkColor from '@components/shared/NavLinkColor'
import FirmBaseLinks from '@components/frontpage/FirmBaseLinks'
// import SalonList from '@components/frontpage/SalonList'
import PartnerList from '@components/frontpage/PartnerList'
import ChangingAdBanner from '@components/frontpage/ChangingAdBanner'
import FrontpageLinks from '@components/frontpage/FrontpageLinks'
import JsonLdFrontpage from 'src/seo/jsonld/JsonLdFrontpage'
import Seo from 'src/seo/components/Seo'
import FAQ from '@components/shared/FAQ'
import MAIN_LINKS from '@components/frontpage/constants/frontpageLinks'

import useT from '@hooks/useTranslation'

import {
  NEW_DEALER_ACCOUNT_PAGE,
  ALL_FEATURED_PAGE, } from '@constants/routes' // NEW_PARTNER_ACCOUNT_PAGE

import dbConnect from 'db/dbConnect'
import FeaturedRepo from 'db/repo/featured'
import SalonRepo from 'db/repo/salon'
import AdvRepo from 'db/repo/adv'
import useUrlSeo from 'src/seo/server/useUrlSeo'

export default function Frontpage ({ featured, partners, // salons
  ads: { searchSection, topSection, middleSection, bottomSection }, seo }) {

  return (
    <Layout footerArticle={seo?.articleFile}>
      <Seo seo={seo} />
      <JsonLdFrontpage />

      <SearchForm banners={searchSection} />

      <H1>{useT(seo.h1 || 'Ogłoszenia')}</H1>

      <FeaturedOffers featured={featured} />

      <div style={{margin:'auto',maxWidth:300}}>
        <NavLinkColor {...{
          t: 'Zobacz wszystkie oferty promowane',
          to: ALL_FEATURED_PAGE,
          color: 'teal',
        }} />
      </div>

      <ScrollToArticle article={{
        headText: useT('Samochody dostawcze - ogłoszenia dostępnych do natychmiastowego odbioru. Porównaj ceny samochodów dostawczych spośród tysięcy ofert na Dostawczaki.pl'),
        buttonText: useT('Zobacz więcej')
      }} />

      <DealerInvitation />
      <FirmBaseLinks />
      {/* <SalonList salons={salons} /> */}
      {/* <PartnerInvitation /> */}
      <PartnerList partners={partners} />

      <ChangingAdBanner data={topSection} />
      <ChangingAdBanner data={middleSection} />
      <ChangingAdBanner data={bottomSection} />

      <FrontpageLinks sections={MAIN_LINKS} />
      <FAQ page="frontpage" qa={seo?.faqFile} title="dostawczaki.pl" />
    </Layout>
  )
}

function SearchForm ({ banners }) {
  return (
    <Background
      collapseWhen="form_expands"
      // priority="true"
      height={427}
      src="/backgrounds/dostawczaki-slider-1.jpeg"
      alt="Samochody, auta ogłoszenia motoryzacyjne"
      z={7}>

      <FrontpageForm banners={banners} />
    </Background>
  )
}

function DealerInvitation () {
  return (
    <Background
      height={450}
      src="/backgrounds/zaproszenie-dla-dealerow.png"
      alt="Dealerzy samochodowi">

        <AccountLink
          h2={ useT("Chcesz sprzedawać samochody dostawcze?") }
          h3={ useT("Załóż konto i dołącz do nas") }
          linkT="Załóż konto"
          linkTo={NEW_DEALER_ACCOUNT_PAGE} />
    </Background>
  )
}

export async function getServerSideProps () {
  await dbConnect()
  return {
    props: {
      featured: {
        bigWindow: await FeaturedRepo.bigWindow(),
        smallWindow: await FeaturedRepo.smallWindow(),
      },

      // salons: await SalonRepo.get(7),
      partners: await SalonRepo.getPartners(7),

      ads: {
        searchSection: await AdvRepo.get('search'),
        topSection: await AdvRepo.get('top'),
        middleSection: await AdvRepo.get('middle'),
        bottomSection: await AdvRepo.get('bottom'),
      },

      seo: await useUrlSeo({ resolvedUrl: '/' }),
    },

    // revalidate: 6 * 60
  }
}

// function PartnerInvitation () {
//   return (
//     <Background
//       height={458}
//       src="/backgrounds/samochody-auta-ogloszenia-motoryzacyjne.webp"
//       alt="Firmy motoryzacyjne we współpracy z dostawczaki.pl">

//         <AccountLink
//           h2={ useT("Publikuj artykuły i oferty produktowe na dostawczaki.pl") }
//           h3={ useT("Załóż konto partnera") }
//           linkT="Załóż konto"
//           linkTo={NEW_PARTNER_ACCOUNT_PAGE} />
//     </Background>
//   )
// }