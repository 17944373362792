const threeChunks = (array) => {
    const len = array.length
    const size = Math.floor(len / 3)
    const oneThird = size < 1
        ? 1
        : size
    const twoThirds = oneThird * 2

    return [
        array.slice(0, oneThird),
        array.slice(oneThird, twoThirds),
        array.slice(twoThirds)
    ]
}

export default threeChunks