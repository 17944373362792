import SalonList from '@components/frontpage/SalonList'
import partnerLink from '@utils/links/salonLink'

export default function PartnerList ({ partners }) {
    return (
        <SalonList
            salons={partners}
            text="Partnerzy"
            linkFunction={partnerLink} />
    )
}