import { useEffect, useState } from 'react'
import FeaturedSlider from './FeaturedSlider'
import JsonLdFrontpageFeatured from 'src/seo/jsonld/JsonLdFrontpageFeatured'
import useT from '@hooks/useTranslation'
import threeChunks from '@utils/array/threeChunks'
import shuffle from '@utils/array/shuffle'

import styles from 'styles/FeaturedOffers.module.css'

const Grid = ({ children }) => <div className={styles.grid}>{ children }</div>

Grid.Left = ({ children }) => <div className={styles.grid_left}>{ children }</div>
Grid.Center = ({ children }) => <div className={styles.grid_center}>{ children }</div>
Grid.Right = ({ children }) => <div className={styles.grid_right}>{ children }</div>

const Title = ({ title, subtitle }) => {
    return (
        <h2 className={styles.title}>
            { title }
            <span className={styles.subtitle}>{ subtitle }</span>
        </h2>
    )
}

const BigWindow = ({ items, time }) => {
    const [featured, setFeatured] = useState(items)
    useEffect(() => {
        setFeatured(shuffle(items))
    }, [])

    return <FeaturedSlider items={featured} time={time} />
}

const SmallWindow = ({ items, time }) => {
    return <FeaturedSlider items={items} time={time} />
}

const FeaturedOffers = ({ featured: { bigWindow, smallWindow }}) => {

    const smallWindows = threeChunks(smallWindow)
    const firstOffer = bigWindow[0] || smallWindow[0]

    return (
        <section className={`${styles.section} container`}>
            <JsonLdFrontpageFeatured offer={firstOffer} />
            <Grid>
                <Grid.Left>
                    <Title title={useT('Ogłoszenia')} subtitle={useT('dostawcze')} />
                    <SmallWindow items={smallWindows[0]} time={7000} />
                </Grid.Left>

                <Grid.Center>
                    <BigWindow items={bigWindow} time={8000} />
                </Grid.Center>

                <Grid.Right>
                    <SmallWindow items={smallWindows[2]} time={5000} />
                    <SmallWindow items={smallWindows[1]} time={6500} />
                </Grid.Right>
            </Grid>
        </section>
    )
}



export default FeaturedOffers