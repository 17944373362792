import strip from '@utils/html/strip'

export default function faq (qa) {
    return {
        "@context":"https://schema.org",
        "@type":"FAQPage",
        "mainEntity": qa
            .map(({ question, answer}) => ({
                "@type":"Question",
                "name": strip(question),
                "acceptedAnswer":{
                   "@type":"Answer",
                   "text": answer,
                }
            }))
    }
}