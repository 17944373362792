import H2 from '@components/frontpage/H2'
import ButtonWrapper from '@components/shared/ButtonWrapper'
import NavLinkColor from '@components/shared/NavLinkColor'
import Icon from '@components/shared/Icon'

import useT from '@hooks/useTranslation'

import { FIRM_BASE_PAGE, NEW_DEALER_ACCOUNT_PAGE } from '@constants/routes'

import styles from 'styles/FirmBaseLinks.module.css'

const firmBaseLink = {
    t: 'Przeszukaj bazę firm',
    title: 'Baza firm',
    to: FIRM_BASE_PAGE,
    color: 'teal',
    iconRight: <Icon>arrow_right_alt</Icon>,
}

const newFirmLink = {
    t: 'Dodaj nową firmę',
    to: NEW_DEALER_ACCOUNT_PAGE,
    color: 'blue',
    iconLeft: <Icon>add</Icon>,
}

const FirmBaseLinks = () => {
    return (
      <>
        <H2>{useT('Katalog branżowy')}</H2>
        <div className={styles.wrapper}>
            <ButtonWrapper>
                <NavLinkColor {...firmBaseLink} />
            </ButtonWrapper>

            <ButtonWrapper>
                <NavLinkColor {...newFirmLink} />
            </ButtonWrapper>
        </div>
      </>
    )
}

export default FirmBaseLinks