import useT from '@hooks/useTranslation'
import styles from 'styles/Empty.module.css'

const Empty = ({ text }) =>
    <div className="container" style={{gridColumn:'1/-1'}}>
        <div className={styles.empty}>
            { text || (useT('Brak rezultatów') + '.') }
        </div>
    </div>

export default Empty