const shuffle = (array) => {
    if (!Array.isArray(array)) return []
    if (array.length < 2) return array

    let temp
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        temp = array[i]
        array[i] = array[j]
        array[j] = temp
    }

    return array
}

export default shuffle